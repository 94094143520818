<template>
    <section>
        <div class="trajectory_content">
            <div v-for="(item, itx) in list" :key="itx" class="trajectory_item">
                <div class="trajectory_item_icon" :style="{backgroundColor: getColor(list, item)}"></div>
                <div class="trajectory_item_line" v-if="(itx + 1) < list.length"></div>
            </div>
        </div>
    </section>
</template>
  
  <script>
  import { formatListDataByKey } from '@/utils/DateUtils.js'
  export default {
    name: "batchQuery",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
        }
    },
    computed: {
        getColor() {
            return (listData, item) => {
                const len = listData.length
                const completedLen = _.filter(listData, it => it.status == 1).length
                console.log(len, completedLen);
                if (len == completedLen) {
                    return '#0bbd87'
                } else if (item.status == 1) {
                    return 'red'
                }
                return '#aba9a9'
            }
        }
    },
    methods: {
        formatListDataByKey,
    }
}
</script>

<style scoped lang="less">
    .trajectory_content {
            width: 100%;
            display: inline-flex;
        .trajectory_item {
            display: inline-flex;
            align-items: center;
            .trajectory_item_icon {
                width: 12px;
                height: 12px;
                border-radius: 10px;
            }
            .trajectory_item_line {
                height: 2px;
                width: 30px;
                background-color: #c2cbcb;
            }
        }
    }
</style>
